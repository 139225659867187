/* RESPONSIBLE TEAM: team-proactive-support */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { emailTemplateTypes, premadeTemplates } from 'embercom/models/data/outbound/constants';

export default class EmailTemplateTitles extends Model {
  @service appService;
  @attr('string') name;
  @attr('string') appId;
  @attr('string') type;
  @attr('date') createdAt;

  get isAnnouncement() {
    return this.id === 'announcement';
  }

  get isPersonal() {
    return this.id === 'personal';
  }

  get isPersonalBlock() {
    return this.id === 'personal_block';
  }

  get isCompany() {
    return this.id === 'company';
  }

  get isCompanyBlock() {
    return this.id === 'company_block';
  }

  get isHtmlMode() {
    return this.id === 'html-block-template';
  }

  get isPlain() {
    return this.id === 'plain';
  }

  get isOfficial() {
    return this.id === 'official';
  }

  get isPlainResponsive() {
    return this.id === 'plain_responsive';
  }

  get isBlockTemplate() {
    return this.type === 'block';
  }

  get isHTMLTemplate() {
    return this.type === 'html';
  }

  get isCustom() {
    return /^\d+$/.test(this.id);
  }

  get isPlainOrCompanyOrAnnouncement() {
    return this.isPlain || this.isCompany || this.isAnnouncement;
  }

  get isPremade() {
    let isOldPremade =
      this.isPlain ||
      this.isCompany ||
      this.isAnnouncement ||
      this.isPersonal ||
      this.isOfficial ||
      this.isPlainResponsive;

    return isOldPremade || this.isPersonalBlock || this.isCompanyBlock;
  }

  get isLegacy() {
    return (
      this.isCompany || this.isPersonal || this.isAnnouncement || this.isOfficial || this.isPlain
    );
  }

  get isResponsive() {
    return this.isPlainResponsive;
  }

  get outboundTemplateType() {
    if (this.isCustom) {
      return emailTemplateTypes.custom;
    }
    if (this.isPremade) {
      return emailTemplateTypes.premade;
    }
    if (this.isHtmlMode) {
      return emailTemplateTypes.htmlMode;
    }
  }

  get outboundTemplateId() {
    if (this.isCustom) {
      return Number(this.id);
    }
    if (this.isPremade) {
      return premadeTemplates[this.id];
    }
    if (this.isHtmlMode) {
      return -1;
    }
  }
}

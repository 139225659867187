/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';
import STEPS from 'embercom/lib/home/steps';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),

  identifier: attr('string'),
  state: attr('string'),
  hasGenerateDataCommand: attr('boolean'),

  guide: readOnly('level.guide'),
  level: belongsTo('onboarding/home/level'),

  completed: equal('state', 'completed'),
  pendingCompleted: equal('state', 'pending_completed'),
  failed: equal('state', 'failed'),
  available: equal('state', 'available'),
  availableOrPendingCompleted: or('available', 'pendingCompleted'),
  locked: equal('state', 'locked'),
  stepData: computed('identifier', function () {
    let stepData = STEPS[this.identifier];
    if (!stepData) {
      let errorMsg = `Step data for "${this.identifier}" is missing. Configure the step in /app/lib/home/steps.js`;
      console.error(errorMsg);
      if (ENV.environment === 'development') {
        throw new Error(errorMsg);
      }
    }

    return stepData;
  }),
  modelData: null,

  requiresHostedMessengerConversation: readOnly('stepData.requiresHostedMessengerConversation'),

  nextStep: computed('identifier', 'level.steps.[]', function () {
    let steps = this.get('level.steps');
    let stepIndex = steps.indexOf(this);
    return steps.objectAt(stepIndex + 1);
  }),

  productTourId: computed('app', 'stepData.getProductTourId', function () {
    if (typeof this.stepData.getProductTourId === 'function') {
      return this.stepData.getProductTourId(this.app);
    }
  }),

  analyticsMetadata: computed('identifier', 'level', 'guide', function () {
    return {
      step_identifier: this.identifier,
      level_identifier: this.level?.get('identifier'),
      guide_identifier: this.guide?.get('identifier'),
    };
  }),

  async loadModelData() {
    if (typeof this.stepData.getModelData === 'function') {
      let modelData = await this.stepData.getModelData(this.store, this.app);
      this.set('modelData', modelData);
    }
  },

  async saveAndRefreshGuide() {
    return await this.save();
  },
});

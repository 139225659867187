/* RESPONSIBLE TEAM: team-messenger */
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import type TemporaryExpectationsLocalizedMessage from './temporary-expectations-localized-message';
import { type BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { dependentKeyCompat } from '@ember/object/compat';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';

export default class TemporaryExpectations extends Model {
  @service declare messengerSettingsService: MessengerSettingsService;

  @attr('boolean') enabled?: boolean;
  @attr('string') message?: string;

  @hasMany('messenger-settings/temporary-expectations-localized-message', { async: false })
  localizedMessages?: SyncHasMany<TemporaryExpectationsLocalizedMessage>;

  updateLocalizedMessage(localeId: string, blocksDocument: BlocksDocument) {
    if (!this.localizedMessages) {
      return;
    }

    let blockText: string = blocksDocument.blocks
      .map(
        (block, index) =>
          (index === 0 ? '' : '\n') + (block.type === 'paragraph' ? block.text : ''),
      )
      .join('');

    let existingMessageForLocale = this.localizedMessages.findBy('localeId', localeId);

    if (existingMessageForLocale) {
      existingMessageForLocale.content = blockText;
    } else {
      let newRecord = this.store.createRecord(
        'messenger-settings/temporary-expectations-localized-message',
        {
          id: localeId,
          localeId,
          content: blockText,
        },
      );
      this.localizedMessages.pushObject(newRecord);
    }
  }

  @dependentKeyCompat
  get defaultLocale() {
    return this.messengerSettingsService.defaultMessengerLocale;
  }

  @dependentKeyCompat
  get defaultTemporaryExpectationsMessage() {
    return this.localizedMessages?.findBy('localeId', this.defaultLocale)?.content;
  }

  get hasUnsavedChanges() {
    return (
      this.hasDirtyAttributes ||
      this.localizedMessages
        ?.toArray()
        .some((message: TemporaryExpectationsLocalizedMessage) => message.hasDirtyAttributes)
    );
  }

  refresh() {
    this.rollbackAttributes();
    this.localizedMessages?.forEach((message) => message.refresh());
  }
}

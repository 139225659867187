/* RESPONSIBLE TEAM: team-workflows */
import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';

export default class CustomBotData extends Fragment {
  @attr('string') selector;
  @attr('boolean', { allowNull: true }) composerDisabled;
  @attr('number') inactivityDuration;
  @attr('boolean', { allowNull: true }) allowManualTrigger;
  @attr('boolean', { allowNull: true }) multilingualEnabled;
  @attr('number', { allowNull: true }) matchPercentage;
}

/* RESPONSIBLE TEAM: team-messenger */

import Model, { attr } from '@ember-data/model';

export default class IdvSetting extends Model {
  @attr('string') declare name: string;
  @attr('string') declare secret: string;
  @attr('boolean') declare supportsAndroid: boolean;
  @attr('boolean') declare supportsIos: boolean;
  @attr('boolean') declare supportsWeb: boolean;
  @attr('boolean') declare deleted: boolean;
  @attr('date') declare createdAt: Date;
}

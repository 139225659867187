/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export const objectives = {
  all: -1,
  onboarding: 0,
  announcement: 1,
  reengagement: 2,
  leadgeneration: 3,
  customerSupport: 4,
  customerEngagement: 5,
  transactional: 6,
};

export const analyticEventNamesForObjectives = {
  [objectives.all]: 'all',
  [objectives.onboarding]: 'onboarding',
  [objectives.announcement]: 'announcement',
  [objectives.reengagement]: 'reengagement',
  [objectives.leadgeneration]: 'leadgeneration',
  [objectives.customerSupport]: 'customer_support',
  [objectives.customerEngagement]: 'customer_engagement',
  [objectives.transactional]: 'transactional',
};

export const objectiveNames = {
  all: 'all',
  onboarding: 'onboarding',
  announcement: 'announcement',
  leadgeneration: 'lead generation',
  reengagement: 're-engagement',
  customerEngagement: 'engagement',
  customerSupport: 'support',
  transactional: 'transactional',
};

export const seriesSupportedTemplateObjectTypes = [objectTypes.series];

export const outboundSupportedTemplateObjectTypes = [
  // Proactive Essentials
  objectTypes.chat,
  objectTypes.banner,
  objectTypes.tooltipGroup,

  // Advanced Proactive
  objectTypes.post,
  objectTypes.email,
  objectTypes.legacyMessageEmail,
  objectTypes.push,
  objectTypes.tour,
  objectTypes.checklist,
  objectTypes.sms,
  objectTypes.survey,
  objectTypes.carousel,
  objectTypes.customBot,
  objectTypes.newsItem,
  objectTypes.whatsapp,
];

export const operatorSupportedTemplateObjectTypes = [
  objectTypes.customBot,
  objectTypes.buttonCustomBot,
  objectTypes.inboundCustomBot,
  objectTypes.triggerableCustomBot,
];

export const contentObjectNames = {
  [objectTypes.chat]: 'chats',
  [objectTypes.post]: 'posts',
  [objectTypes.email]: 'emails',
  [objectTypes.legacyMessageEmail]: 'emails',
  [objectTypes.customBot]: 'custom bots',
  [objectTypes.tour]: 'product tours',
  [objectTypes.push]: 'mobile push',
  [objectTypes.carousel]: 'mobile carousels',
  [objectTypes.banner]: 'banners',
  [objectTypes.sms]: 'SMS',
  [objectTypes.survey]: 'surveys',
  [objectTypes.tooltipGroup]: 'tooltips',
  [objectTypes.newsItem]: 'news items',
  [objectTypes.checklist]: 'checklists',
  [objectTypes.whatsapp]: 'WhatsApp',
};

export const paywallFeatures = {
  [objectTypes.chat]: 'auto_messaging',
  [objectTypes.post]: 'post_in-app_message',
  [objectTypes.email]: 'email_messages',
  [objectTypes.customBot]: 'bot_workflows',
  [objectTypes.tour]: 'product_tours',
  [objectTypes.push]: 'push_only',
  [objectTypes.carousel]: 'mobile_carousels',
  [objectTypes.banner]: 'banner_messages',
  [objectTypes.survey]: 'surveys',
  [objectTypes.tooltipGroup]: 'tooltips',
  [objectTypes.sms]: 'sms',
  [objectTypes.whatsapp]: 'whatsapp_integration',
  [objectTypes.newsItem]: 'news',
  [objectTypes.checklist]: 'checklists',
};

export const contentObjectFeatures = {
  [objectTypes.whatsapp]: ['whatsapp_outbound_billing_feature'],
};

export const contentObjectsInBeta = [];

export const contentObjectsWithNewTag = [];

export const popular = 7;

export const useCases = {
  customerSupport: 4,
  onboarding: 0,
  transactional: 6,
  customerEngagement: 5,
};

export const useCaseNames = {
  customerSupport: 'proactive support',
  onboarding: 'onboarding',
  transactional: 'transactional',
  customerEngagement: 'engagement',
};

export const channels = {
  messenger: 8,
  website: 9,
  mobile: 10,
};

export const channelNames = {
  messenger: 'messenger',
  website: 'website',
  mobile: 'mobile',
};

export const analyticEventNames = {
  ...analyticEventNamesForObjectives,
  [popular]: 'popular',
  [channels.messenger]: 'messenger',
  [channels.website]: 'website',
  [channels.mobile]: 'mobile',
};

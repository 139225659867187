/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { equal, filterBy, not, mapBy, readOnly } from '@ember/object/computed';
import { computed } from '@ember/object';
import Levels from 'embercom/lib/home/levels';
import { equalToProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

export default Model.extend({
  appService: service(),
  app: readOnly('appService.app'),
  identifier: attr('string'),
  guide_section: attr('string'),
  isInProgress: false,
  guide: belongsTo('onboarding/home/guide'),
  state: attr('string'),
  steps: hasMany('onboarding/home/step'),
  completed: equal('state', 'completed'),
  notCompleted: not('completed'),
  available: equal('state', 'available'),
  completedSteps: filterBy('steps', 'state', 'completed'),
  availableSteps: filterBy('steps', 'state', 'available'),
  completionProgress: computed('completedSteps.length', 'steps.length', function () {
    return (this.completedSteps.length / this.steps.length) * 100;
  }),

  isLatestAvailableLevel: equalToProperty('identifier', 'guide.latestAvailableLevel.identifier'),

  levelData: computed('identifier', function () {
    return Levels[this.identifier];
  }),
  allStepsComplete: equalToProperty('completedSteps.length', 'steps.length'),

  nextAvailableLevel: computed('identifier', 'guide', function () {
    let levels = this.guide.get('levels');
    let levelIndex = levels.indexOf(this);
    let laterLevels = levels.slice(levelIndex + 1);
    return laterLevels.findBy('available');
  }),

  availableStepsData: mapBy('availableSteps', 'stepData'),
  availableStepsTimeToCompleteInMinutes: mapBy('availableStepsData', 'timeToCompleteInMinutes'),

  timeToCompleteInMinutes: computed('availableStepsTimeToCompleteInMinutes.[]', function () {
    let timePerStep = this.availableStepsTimeToCompleteInMinutes.filter(Number.isInteger);
    return timePerStep.reduce((accumulator, time) => accumulator + time, 0);
  }),

  async loadSteps() {
    let steps = this.steps
      .filter((step) => !!step.stepData.loadModelDataOnDemand === false)
      .map((step) => step.loadModelData());
    return await Promise.all(steps);
  },
});

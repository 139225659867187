/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-reporting */
import { isEmpty, isPresent } from '@ember/utils';
import { getNameFromFilterIdentifier } from 'embercom/lib/reporting/custom/filter-helpers';
import { closestSafeIntervalFor, rangeInDays } from 'embercom/lib/reporting/custom/safe-limits';
import { compact } from 'underscore';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { capitalize } from '@ember/string';
import {
  HORIZONTAL_BAR_TYPES,
  UNLIMITED_RESULTS,
  visualizationSupportsFeature,
} from 'embercom/models/reporting/custom/visualization-options';
import { requestNameFor } from 'embercom/lib/reporting/chart-data-resource-compatible-helper';

const MAX_HEIGHT = '470px';
const DEFAULT_TABLE_ROW_COUNT = 10;
export default class RenderableChart {
  @service intl;
  @service appService;
  @service reportingMetrics;

  constructor(chartState, reportState, owner) {
    setOwner(this, owner);
    this.id = chartState.id;
    this.viewBy = chartState.viewBy;
    this.segmentBy = chartState.segmentBy;
    this.segmentByDisplayLimit = chartState.segmentByDisplayLimit;
    this.segmentByDisplayOther = chartState.segmentByDisplayOther;
    this.visualizationType = chartState.visualizationType;
    this.componentName = chartState.componentName;
    this.reportState = reportState;
    this.dateRange = this.selectDateRange(reportState, chartState);
    this.viewByDisplayLimit = chartState.viewByDisplayLimit;
    this.viewByDisplayOther = chartState.viewByDisplayOther;
    this.stacked = chartState.stacked;
    this.isStacked = chartState.isStacked;
    this.isTable = chartState.isTable;
    this.isCounter = chartState.isCounter;
    this.isBespoke = chartState.isBespoke;
    this.isArea = chartState.isArea;
    this.isHeatmap = chartState.isHeatmap;
    this.isInvisible = chartState.isInvisible;
    this.isComparisonCounter = chartState.isComparisonCounter;
    chartState.cleanupConfig(this);
    this.isBrokenDownByTime = chartState.isBrokenDownByTime;
    this.isSegmentedByTime = chartState.isSegmentedByTime;
    this.segmentByTimeInterval = chartState.segmentByTimeInterval;
    this.ignoredFilters = new Set(chartState.ignoredFilters);
    this.showDefaultChartTitle = chartState.showDefaultChartTitle;
    this.defaultDrillInAttributes = chartState.defaultDrillInAttributes;
    this.visualizationOptions = chartState.visualizationOptions;
    this.showTimeComparison = this.visualizationOptions?.showTimeComparison;
    this.target = this.visualizationOptions?.target;

    let dateRangeInDays = rangeInDays(this.dateRange.startMoment, this.dateRange.endMoment);
    this.viewByTimeInterval = closestSafeIntervalFor(
      chartState.viewByTimeInterval,
      dateRangeInDays,
      chartState.visualizationType,
    );

    this.tooltip = sanitizeHtml(chartState.tooltip?.string || chartState.tooltip);
    this.chartSeries = chartState.chartSeries;

    if (isPresent(this.segmentBy) && this.chartSeries.length > 1) {
      throw new Error('segmentBy cannot be used with multiple chartSeries');
    }
    if (this.isHeatmap && !this.chartSeries.firstObject.metric.supportsHeatmaps) {
      throw new Error('This metric does not support the heatmap visualization');
    }
    this.numberRowsVisible = chartState.numberRowsVisible;
    this.shouldRenderChrome = chartState.shouldRenderChrome;
    this.viewBySortDirection = chartState.viewBySortDirection;
  }

  get app() {
    return this.appService.app;
  }

  supportsFeature(featureName) {
    return visualizationSupportsFeature(
      this.visualizationType,
      featureName,
      this.isMultimetric,
      this.app,
    );
  }

  get isMultimetric() {
    return this.chartSeries?.length > 1;
  }

  get isHorizontalBar() {
    return HORIZONTAL_BAR_TYPES.includes(this.visualizationType);
  }

  get filters() {
    throw new Error('filters should not be accessed directly, use chartSeries instead');
  }

  selectDateRange(reportState, chartState) {
    if (isPresent(reportState?.dateRange)) {
      return reportState?.dateRange;
    } else if (isPresent(reportState?.timezone)) {
      return chartState.getRangeWithTimeZone(reportState?.timezone);
    }
    return chartState.range;
  }

  static getChartTimeFrom(chart) {
    let property = chart.chartSeries.firstObject.metric.timeProperty;

    return {
      start: chart.range.startMoment.valueOf(),
      end: chart.range.endMoment.valueOf(),
      interval: chart.range.interval,
      property,
    };
  }

  get improvementDirection() {
    return this.chartSeries.objectAt(0).metric.improvementDirection;
  }

  get seriesColors() {
    return compact(this.chartSeries?.map((series) => series.color)) || [];
  }

  get description() {
    let name = this.metricDisplayName;

    if (this.isMultimetric) {
      let numberOfMetrics = this.chartSeries.length - 1;
      name = name
        .concat(' ')
        .concat(
          this.intl.t('reporting.chart.multimetric-additional-description', { numberOfMetrics }),
        );
    }

    if (this.visualizationType === 'heatmap') {
      name = this.intl.t('reporting.chart.default-title-heatmap', { name });
      return name;
    }

    if (this.viewBy === 'time') {
      name = `${name} by ${this.viewByTimeInterval || this.dateRange.interval}`;
    } else if (this.viewBy && !this.reportState?.isStatic) {
      name = `${name} by ${getNameFromFilterIdentifier(this.viewBy, this).toLowerCase()}`;
    }
    if (this.segmentBy === 'time') {
      name = `${name} and ${this.segmentByTimeInterval || this.dateRange.interval}`;
    } else if (this.segmentBy) {
      name = `${name} and ${getNameFromFilterIdentifier(this.segmentBy, this).toLowerCase()}`;
    }
    return name;
  }

  metricDescription(chartSeries) {
    let name = chartSeries.metricDisplayName;

    if (this.visualizationType === 'heatmap') {
      name = this.intl.t('reporting.chart.default-title-heatmap', { name });
      return name;
    }

    if (this.viewBy === 'time') {
      name = `${name} by ${this.viewByTimeInterval || this.dateRange.interval}`;
    } else if (this.viewBy && !this.reportState?.isStatic) {
      name = `${name} by ${getNameFromFilterIdentifier(this.viewBy, this).toLowerCase()}`;
    }
    if (this.segmentBy === 'time') {
      name = `${name} and ${this.segmentByTimeInterval || this.dateRange.interval}`;
    } else if (this.segmentBy) {
      name = `${name} and ${getNameFromFilterIdentifier(this.segmentBy, this).toLowerCase()}`;
    }
    return name;
  }

  get segmentByDisplayName() {
    if (isEmpty(this.segmentBy)) {
      return 'None';
    }
    if (this.segmentBy === 'time') {
      let timeLabel = this.segmentByTimeInterval || this.dateRange.interval;
      return capitalize(timeLabel ?? '');
    }
    return getNameFromFilterIdentifier(this.segmentBy, this);
  }

  get viewByDisplayName() {
    if (this.viewBy === 'time') {
      let timeLabel = this.viewByTimeInterval || this.dateRange.interval;
      return capitalize(timeLabel ?? '');
    }
    return getNameFromFilterIdentifier(this.viewBy, this);
  }

  get metricUnit() {
    return this.chartSeries.firstObject.metric.unit;
  }

  get tooltipTranslationKeys() {
    return Object.fromEntries(
      this.chartSeries.map((series) => {
        return [series.metricId, series.metric.dataPointFormat];
      }),
    );
  }

  get showFiltersNotAppliedWarning() {
    return (
      isPresent(this.reportState.filters?.filters) &&
      this.componentName === 'fin-rating-remarks-table'
    );
  }

  get metricDisplayName() {
    if (this.isInvisible) {
      return;
    } else {
      return this.chartSeries.objectAt(0).metricDisplayName;
    }
  }

  get filterableProperties() {
    return this.metric(0).supportedFilters.map((p) => p.id);
  }

  get filterableMetricProperties() {
    return this.metric(0).supportedFilters;
  }

  get time() {
    return {
      start: this.dateRange.startMoment.valueOf(),
      end: this.dateRange.endMoment.valueOf(),
      interval: this.dateRange.interval,
      property: this.timeProperty,
    };
  }

  get timeProperty() {
    return this.chartSeries.firstObject.metric.timeProperty;
  }

  get maxHeight() {
    return MAX_HEIGHT;
  }

  get loadingRowCount() {
    return DEFAULT_TABLE_ROW_COUNT;
  }

  get source() {
    return this.chartSeries.firstObject.metric.firstSource;
  }

  get teammateProperty() {
    return this.firstMetric?.teammateProperty;
  }

  get teamProperty() {
    return this.firstMetric?.teamProperty;
  }

  get displayNameForTeammateProperty() {
    return getNameFromFilterIdentifier(this.teammateProperty, this);
  }

  get displayNameForTeamProperty() {
    if (!this.teamProperty) {
      return undefined;
    }
    return getNameFromFilterIdentifier(this.teamProperty, this);
  }

  get firstMetric() {
    return this.chartSeries?.firstObject?.metric;
  }

  metric(index) {
    if (isEmpty(this.chartSeries)) {
      throw new Error('metric(index) can only be used with chartSeries');
    }
    return this.chartSeries.objectAt(index)?.metric;
  }

  get metrics() {
    if (isEmpty(this.chartSeries)) {
      throw new Error('metrics getter can only be used with chartSeries');
    }
    return this.chartSeries.map((series) => series.metric);
  }

  get showTableSummaryRow() {
    return this.isTable && this.visualizationOptions?.showSummaryRow;
  }

  get supportsUnlimitedResults() {
    return visualizationSupportsFeature(
      this.visualizationType,
      UNLIMITED_RESULTS,
      this.isMultimetric,
      this.app,
    );
  }

  get isScrollable() {
    return this.isBrokenDownByTime || !this.supportsUnlimitedResults;
  }

  get tableSortState() {
    let valuePath = this.isBrokenDownByTime
      ? 'groupName'
      : requestNameFor(0, this.firstMetric, true);
    return { valuePath, direction: 'desc' };
  }

  buildViewConfigForTable() {
    if (!this.metricUnit) {
      return;
    }
    if (this.numberRowsVisible && this.numberRowsVisible > 0) {
      let viewConfig = {
        metrics: this.chartSeries?.map((series) => series.metric),
        rowsVisible: this.numberRowsVisible,
        sortState: this.tableSortState,
        scrollable: false,
        showRawValues: true,
        ...(this.showTableSummaryRow && {
          rowOrder: [{ position: 0, valuePath: 'groupName', value: 'row-summary' }],
          isVisibleRow: (row, shouldShowSummaryData) =>
            row.groupName !== 'row-summary' ||
            (row.groupName === 'row-summary' && shouldShowSummaryData),
        }),
      };
      return viewConfig;
    }
    return {
      metrics: this.chartSeries?.map((series) => series.metric),
      rowsVisible: DEFAULT_TABLE_ROW_COUNT,
      sortState: this.tableSortState,
      scrollable: true,
      rowOrder: [{ position: 0, valuePath: 'groupName', value: 'row-summary' }],
      showRawValues: true,
    };
  }

  updateViewConfig(viewConfig) {
    let updatedViewConfig = { ...viewConfig };
    updatedViewConfig.sortState = this.tableSortState;
    updatedViewConfig.scrollable = this.isScrollable;
    updatedViewConfig.reloadRowsVisible = this.supportsUnlimitedResults;
    return updatedViewConfig;
  }
}

/* RESPONSIBLE TEAM: team-knowledge-interop */
import Model, { attr } from '@ember-data/model';
import type { Block } from '@intercom/interblocks.ts';

export enum ReviewType {
  CREATE_CONTENT = 'create_content',
  EDIT_CONTENT = 'edit_content',
  DELETE_CONTENT = 'delete_content',
}

export type SuggestionToEdit = {
  indices: [number, number];
  editedBlocks: Block[];
};

export default class ContentReviewRequest extends Model {
  @attr('number') declare reviewerId: number;
  @attr('number') declare status: number;
  @attr('number') declare entityId: number;
  @attr('number') declare entityType: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare reviewReason: string;
  @attr('string') declare reviewType: ReviewType;
  @attr() declare updatedSuggestionBlocks: Array<Block>;
  @attr() declare suggestionToEdit?: SuggestionToEdit | null;
}

/* RESPONSIBLE TEAM: team-workflows */
import { intersection } from 'underscore';
import Model, { attr, hasMany, type SyncHasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import Group from 'embercom/models/operator/visual-builder/group';

// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type EditorConfig from 'embercom/objects/visual-builder/configuration/editor';
import { type TrackingMetadata } from 'embercom/objects/visual-builder/tracking-metadata';
import type AttributeDescriptor from 'embercom/models/operator/visual-builder/attribute-descriptor';

const Validations = buildValidations({
  groups: [validator('has-many')],
  localVariablesExist: [validator('workflow-local-variables-exist')],
});

const FIN_WORKFLOW_PREVIEW_ID = 'visual-builder-object-workflow-preview';

export default class Workflow extends Model.extend(Validations) {
  @service declare appService: any;
  @service contentEditorService: any;
  @attr declare originalPayload: object;

  @hasMany('operator/visual-builder/group', { async: false, inverse: 'workflow' })
  declare groups: SyncHasMany<Group>;
  @attr('number') declare workflowInstanceEntityType: number;
  @attr('string') declare workflowInstanceEntityId: string;
  // This is a temporary fix to allow us to hide the Handover to Resolution Bot step for 'conversation-started' bots
  // We aim to refactor Visual Editor config so that the Workflow itself has more control over its own config
  @attr('string') declare workflowInstanceTrigger: string;
  @attr('string') declare workflowInstanceId: string;

  @hasMany('operator/visual-builder/attribute-descriptor', { async: false, inverse: 'workflow' })
  declare attributeDescriptors: SyncHasMany<AttributeDescriptor>;

  @tracked editorConfig?: EditorConfig;
  @tracked trackingMetadata?: TrackingMetadata;
  @tracked hasBeenEdited: boolean;

  constructor(...args: ConstructorParameters<typeof Model>) {
    super(...args);

    this.hasBeenEdited = false;
  }

  get analyticsData() {
    return {
      app_id: this.appService?.app?.id,
      is_live: this.trackingMetadata?.isLive,
      workflow_instance_entity_id: this.workflowInstanceEntityId,
      workflow_instance_entity_type: this.workflowInstanceEntityType,
      object: 'visual_builder_workflow',
    };
  }

  @computed('groups.{length,@each.localVariableIdentifiersUsed}')
  get localVariableIdentifiersUsed(): string[] {
    return this.groups
      .map((group) => group.localVariableIdentifiersUsed)
      .reduce((a, b) => a.concat(b), []);
  }

  @computed('groups.@each.supportedChannels')
  get supportedChannels() {
    let supportedChannels = this.groups.map((group) => group.supportedChannels);
    return intersection(...supportedChannels);
  }

  @computed('hasDirtyAttributes', 'hasBeenEdited', 'groups.@each.hasUnsavedChanges')
  get hasUnsavedChanges() {
    return this.hasDirtyAttributes || this.hasBeenEdited || this.groups.isAny('hasUnsavedChanges');
  }

  @computed('groups.@each.isCustomerFacing')
  get isCustomerFacing() {
    return this.groups.isAny('isCustomerFacing');
  }

  @computed('groups.@each.useAiAnswers')
  get useAiAnswers() {
    return this.groups.isAny('useAiAnswers');
  }

  @computed('attributeDescriptors')
  get writeableAttributeDescriptors() {
    return this.attributeDescriptors.filter(
      (attributeDescriptor) => !attributeDescriptor.referenceOnly,
    );
  }

  get ruleset() {
    return this.contentEditorService.ruleset;
  }

  rollbackAttributes() {
    this._restoreOriginalPayload();

    let groups = this.groups.toArray();
    groups.forEach((group) => group.rollbackAttributes());
    this.attributeDescriptors
      .toArray()
      .forEach((attributeDescriptor) => attributeDescriptor.rollbackAttributes());
    super.rollbackAttributes();
  }

  get isFinPreview() {
    return this.id === FIN_WORKFLOW_PREVIEW_ID;
  }

  get isZendeskWorkflow() {
    return (
      this.workflowInstanceTrigger === 'new-zendesk-ticket' ||
      this.workflowInstanceTrigger === 'new-zendesk-sunshine-conversation'
    );
  }

  get isSalesforceWorkflow() {
    return this.workflowInstanceTrigger === 'new-salesforce-case';
  }

  get isStandaloneWorkflow() {
    return this.isZendeskWorkflow || this.isSalesforceWorkflow;
  }

  serialize(options?: Parameters<Model['serialize']>[0] & { sanitize: boolean }) {
    let json = super.serialize(...arguments);

    if (options?.sanitize) {
      Workflow.sanitizePayload(json);
    }

    return json;
  }

  static sanitizePayload(workflowPayload: { groups?: object[] }) {
    workflowPayload.groups?.forEach((group) => Group.sanitizePayload(group));
  }

  _restoreOriginalPayload() {
    this.store.pushPayload({ 'operator/visual-builder/workflow': this.originalPayload });
  }
}

/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { and, bool, gt, not, notEmpty, or, equal, reads } from '@ember/object/computed';
import { fragment } from 'ember-data-model-fragments/attributes';
import currencyFormatter from 'embercom/lib/currency-formatter';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';
import { STRIPE_MIGRATION_REASON, DefaultPaymentMethodType } from 'embercom/lib/billing';
import { inject as service } from '@ember/service';

export default Model.extend({
  intl: service(),
  availableSeatTypes: attr(),
  attachInvoicePdfToEmail: attr('boolean'),
  billingAddress: fragment('billing/address'),
  billingAddressees: attr(),
  canModifyOrRemoveProducts: attr('boolean'),
  canAddOrEditCoupon: attr('boolean'),
  peopleCountLimit: attr(),
  cardBrand: attr(),
  cardExpiryMonth: attr(),
  cardExpiryYear: attr(),
  cardLastFour: attr(),
  contactEmail: attr(),
  couponCode: attr(),
  couponExpiresAt: attr(),
  couponAmountOffInCents: attr(),
  couponPercentOff: attr(),
  currentBillingCycleStartDate: attr(),
  currentBillingCycleEndDate: attr(),
  currentlyOnEarlyStage: attr('boolean'),
  currentlyOnEarlyStage99: attr('boolean'),
  currentlyOnValueBasedPricingMar2021EarlyStage: attr('boolean'),
  entityName: attr(),
  estimatedMonthlyCost: attr(),
  freeSeatCount: attr(),
  hadPreviousSubscription: attr(),
  hasActiveSubscription: attr(),
  hasActiveTrialWithoutCreditCard: attr(),
  hasActiveTrials: attr(),
  hasCustomPricing: attr(),
  eligibleForEarlyStage: attr('boolean'),
  hasAppliedForEarlyStage: attr('boolean'),
  validEarlyStageApplicant: attr('boolean'),
  earlyStageApplicationSolutionId: attr(),
  nonIrishVatNumber: attr(),
  intercomAccountCredit: attr(),
  lastDailyAppStatCreated: attr('date'),
  latestUserCount: attr(),
  latestMonthlyActivePeopleCount: attr(),
  latestQuarterlyActivePeopleCount: attr(),
  latestActiveAgentCount: attr(),
  latestDailyAdminCount: attr(),
  manuallyInvoiced: attr('boolean'),
  notifyOfInvoices: attr('boolean'),
  onPricing5: attr('boolean'),
  isStripeCustomer: attr(),
  ongoingCancellableStripeMigration: attr('boolean'),
  pricePerSeat: attr(),
  pricePerSeatTypes: attr(),
  seatLimit: attr(),
  seatTypePriceByPlan: attr(),
  showBillingCycleMetricsInBillingSummary: attr('boolean'),
  shiftedCycleMetricsAvailable: attr('boolean'),
  includedSeatCountPerType: attr(),
  shippingAddress: fragment('billing/address'),
  subscriptionCancelledAt: attr('date'),
  subscriptionWillBeCancelledAt: attr('date'),
  subscriptionWillBeCancelledWithReason: attr(),
  formattedPaymentMethod: attr(),
  hasSucceedingSubscription: attr('boolean'),
  subscriptionPricedPerProduct: attr('boolean'),
  matchEarlyStageDetectionCriterias: attr('boolean'),
  vatNumber: attr(),
  pendingEarlyStageApplicant: attr('boolean'),
  firstTrialStartedAt: attr('date'),
  lastTrialStartedAt: attr('date'),
  lastTrialEndedAt: attr('date'),

  subscription: fragment('billing/subscription'),
  earlyStagePartner: attr('boolean'),
  isAnnualContractPrimarySubscription: attr('boolean'),
  isAnnualContractSecondarySubscription: attr('boolean'),

  hasNoActiveSubscription: not('hasActiveSubscription'),

  currentEarlyStageCustomer: or('currentlyOnEarlyStage', 'currentlyOnEarlyStage99'),

  hasBillingAddress: notEmpty('billingAddress'),
  hasNoBillingAddress: not('hasBillingAddress'),
  hasShippingAddress: notEmpty('shippingAddress'),
  hasNoShippingAddress: not('hasShippingAddress'),
  customerCohort: attr(),
  inSelfServeTrial: or('inCardlessTrial', 'inTrialExtension'),
  inExpiredSelfServeTrial: or('inExpiredCardlessTrial', 'inExpiredTrialExtension'),
  inCardlessTrial: attr('boolean'),
  inExpiredCardlessTrial: attr('boolean'),
  inTrialExtension: attr('boolean'),
  inExpiredTrialExtension: attr('boolean'),
  everInCardlessTrial: attr('boolean'),

  hasActiveTrialSubscription: attr('boolean'),
  //If the customer has an active trial subscription that is not a cardless trial
  //Meaning they are trialling every plan in their subscription and it would had to have been started via billing admin
  hasActiveBillingAdminTrialSubscription: computed(
    'hasActiveTrialSubscription',
    'inSelfServeTrial',
    'inExpiredSelfServeTrial',
    function () {
      return (
        this.hasActiveTrialSubscription && !this.inSelfServeTrial && !this.inExpiredSelfServeTrial
      );
    },
  ),
  formattedSubscriptionWillBeCancelledAt: computed('subscriptionWillBeCancelledAt', function () {
    return this.intl.formatDate(this.subscriptionWillBeCancelledAt, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }),

  isOnInitialTrial: or('inSelfServeTrial', 'hasActiveTrialWithoutCreditCard'),

  formattedCardExpiresAt: computed('cardExpiryDate', function () {
    return this.intl.formatDate(this.cardExpiryDate, {
      month: 'short',
      year: 'numeric',
    });
  }),

  cardExpiryDate: computed(
    'cardExpiryMonth',
    'cardExpiryYear',
    'formattedPaymentMethod.details.{expiry_month,expiry_year}',
    'hasFormattedPaymentMethodData',
    function () {
      if (this.hasFormattedPaymentMethodData) {
        return moment(
          `${this.formattedPaymentMethod?.details?.expiry_year}${this.formattedPaymentMethod?.details?.expiry_month}`,
          'YYYYMM',
        );
      }

      return moment(`${this.cardExpiryYear}${this.cardExpiryMonth}`, 'YYYYMM');
    },
  ),

  hasStripeLinkAsDefaultPaymentMethod: equal(
    'formattedPaymentMethod.type',
    DefaultPaymentMethodType.StripeLink,
  ),

  hasFormattedPaymentMethodData: computed('formattedPaymentMethod.type', function () {
    return !!this.formattedPaymentMethod?.type;
  }),

  hasCardAsDefaultPaymentMethod: computed(
    'formattedPaymentMethod.type',
    'hasFormattedPaymentMethodData',
    'validCardFields',
    function () {
      if (this.hasFormattedPaymentMethodData) {
        return this.formattedPaymentMethod?.type === DefaultPaymentMethodType.Card;
      }

      return this.validCardFields;
    },
  ),

  existingCardDetails: computed(
    'cardBrand',
    'cardExpiryMonth',
    'cardExpiryYear',
    'cardLastFour',
    'formattedPaymentMethod.details.{brand,expiry_month,expiry_year,last4}',
    'hasFormattedPaymentMethodData',
    function () {
      if (this.hasFormattedPaymentMethodData) {
        return {
          cardBrand: this.formattedPaymentMethod?.details?.brand,
          cardLastFour: this.formattedPaymentMethod?.details?.last4,
          cardExpiryMonth: this.formattedPaymentMethod?.details?.expiry_month,
          cardExpiryYear: this.formattedPaymentMethod?.details?.expiry_year,
        };
      } else {
        return {
          cardBrand: this.cardBrand,
          cardLastFour: this.cardLastFour,
          cardExpiryMonth: this.cardExpiryMonth,
          cardExpiryYear: this.cardExpiryYear,
        };
      }
    },
  ),

  stripeLinkAccountEmailAddress: reads('formattedPaymentMethod.details.email'),

  formattedCouponExpiresAt: computed('couponExpiresAt', function () {
    if (this.couponExpiresAt) {
      return `Expires ${moment(this.couponExpiresAt).format(dateAndTimeFormats.dateHumanized)}`;
    } else {
      return 'Never expires';
    }
  }),

  relativeLastDailyAppStatCreated: computed('lastDailyAppStatCreated', function () {
    let lastDailyAppStatCreated = this.lastDailyAppStatCreated;

    if (!lastDailyAppStatCreated) {
      return null;
    }

    return moment(lastDailyAppStatCreated).fromNow();
  }),

  validCardFields: and('cardLastFour', 'cardBrand', 'cardExpiryMonth', 'cardExpiryYear'),
  hasDefaultPaymentMethod: or(
    'validCardFields',
    'hasStripeLinkAsDefaultPaymentMethod',
    'hasCardAsDefaultPaymentMethod',
  ),

  subscriptionWillBeCancelledAndHasPaymentMethod: and(
    'subscriptionWillBeCancelled',
    'hasDefaultPaymentMethod',
  ),

  hasAccountCredit: gt('intercomAccountCredit', 0),
  formattedAccountCredit: computed('intercomAccountCredit', function () {
    return currencyFormatter(this.intercomAccountCredit);
  }),

  subscriptionWillBeCancelled: bool('subscriptionWillBeCancelledAt'),

  subscriptionWasCancelled: bool('subscriptionCancelledAt'),

  hasEverHadSubscription: or('hasActiveSubscription', 'hadPreviousSubscription'),
  hasNeverHadSubscription: not('hasEverHadSubscription'),

  latestUserCountDisplayFigure: computed('latestUserCount', function () {
    let latestUserCount = this.latestUserCount;
    return latestUserCount > 250 ? latestUserCount : 250;
  }),

  isUserCountLessThanPriceSetLowerBound: computed(
    'latestUserCountDisplayFigure',
    'subscription.priceSetLowerBound',
    function () {
      let priceSetLowerBound = this.get('subscription.priceSetLowerBound');
      let latestUserCountDisplayFigure = this.latestUserCountDisplayFigure;

      if (!priceSetLowerBound) {
        return false;
      }

      return latestUserCountDisplayFigure < priceSetLowerBound;
    },
  ),

  // User count to use when calculating price
  billingUserCount: computed(
    'latestUserCountDisplayFigure',
    'subscription.priceSetLowerBound',
    function () {
      let latestUserCountDisplayFigure = this.latestUserCountDisplayFigure;
      let priceSetLowerBound = this.get('subscription.priceSetLowerBound');

      return Math.max(latestUserCountDisplayFigure, priceSetLowerBound);
    },
  ),

  cancellationRequestedOutsideMigration: computed(
    'subscriptionWillBeCancelledAt',
    'subscriptionWillBeCancelledWithReason',
    function () {
      return (
        !!this.subscriptionWillBeCancelledAt &&
        this.subscriptionWillBeCancelledWithReason !== STRIPE_MIGRATION_REASON
      );
    },
  ),

  earlyStageProgrameName: computed(
    'currentlyOnEarlyStage',
    'earlyStagePartner',
    'intl.locale',
    function () {
      if (this.currentlyOnEarlyStage && this.earlyStagePartner) {
        return this.intl.t('app.models.customer.early_stage_program_names.free');
      } else if (this.currentlyOnEarlyStage) {
        return this.intl.t('app.models.customer.early_stage_program_names.standard');
      } else {
        return null;
      }
    },
  ),
});

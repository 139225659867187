/* RESPONSIBLE TEAM: team-workflows */

import Step, { IsCustomerFacing } from 'embercom/models/operator/visual-builder/step';
import type Store from '@ember-data/store';
import { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';
import { SUPPORTED_CHANNELS } from 'embercom/lib/operator/custom-bots/constants-2';
import { TargetContext } from 'embercom/objects/operator/configuration/custom-bot';

const Validations = buildValidations({
  blockUpdateAfter: [
    validator('number', {
      allowNone: true,
      integer: true,
      gte: 0,
      lte: 180 * 24 * 60 * 60, // 180 days in seconds
      messageKey: 'operator.workflows.visual-builder.validations.conversation-ratings',
    }),
  ],
  blockRatingAfter: [
    validator('number', {
      allowNone: true,
      integer: true,
      gte: 60,
      lte: 180 * 24 * 60 * 60, // 180 days in seconds
      messageKey: 'operator.workflows.visual-builder.validations.conversation-ratings',
    }),
  ],
});

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
export default class ConversationRatings extends Step.extend(Validations) {
  @attr('number') declare blockUpdateAfter?: number;
  @attr('number') declare blockRatingAfter?: number;
  @attr('boolean') declare waitForRating?: boolean;
  @attr('boolean') declare enableComposerWhileWaitingForRating?: boolean;

  get supportedChannels() {
    return SUPPORTED_CHANNELS.conversationRatings;
  }

  static get supportedContexts() {
    return [TargetContext.Conversation, TargetContext.CustomerTicket];
  }

  static get requiredBillingFeature() {
    return 'conversation_ratings';
  }

  static get isStepGroupEnding() {
    return false;
  }

  static get isStepTerminal() {
    return true;
  }

  static createNewStep(store: Store): ConversationRatings {
    return store.createRecord('operator/visual-builder/step/conversation-ratings', {
      type: 'operator/visual-builder/step/conversation-ratings',
      waitForRating: false,
      enableComposerWhileWaitingForRating: false,
    });
  }

  static get canBeCustomerFacing() {
    return IsCustomerFacing.Yes;
  }

  get blockRatingAfterDays() {
    return Math.floor((this.blockRatingAfter || 0) / DAY);
  }

  get blockRatingAfterHours() {
    return Math.floor(((this.blockRatingAfter || 0) % DAY) / HOUR);
  }

  get blockRatingAfterMinutes() {
    return Math.floor(((this.blockRatingAfter || MINUTE) % HOUR) / MINUTE);
  }

  get blockUpdateAfterDays() {
    return Math.floor((this.blockUpdateAfter || 0) / DAY);
  }

  get blockUpdateAfterHours() {
    return Math.floor(((this.blockUpdateAfter || 0) % DAY) / HOUR);
  }

  get blockUpdateAfterMinutes() {
    return Math.floor(((this.blockUpdateAfter || 0) % HOUR) / MINUTE);
  }
}

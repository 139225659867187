/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';

export default Model.extend({
  appService: service(),
  admin_id: attr(),
  user_anonymous_id: attr(),
  conversation_id: attr(),

  claim() {
    return put(`/ember/onboarding/hosted_messenger_created_conversations/${this.id}/claim`, {
      app_id: this.get('appService.app.id'),
    });
  },
});
